/* path/to/your/app/webpane/page/pane_view/pane_view.css */
.pane-view-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pane-view-header {
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.pane-view-body {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.pane-view-list {
  flex: 0 0 auto;
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.o_pane_view_splitter {
  flex: 0 0 auto;
  width: 8px;
  background: url(/assets/paneview/img/vsizegrip.png) center center no-repeat lightgray;
  min-height: 200px;
  cursor: col-resize;
}
.o_pane_view_splitter:hover {
  background: url(/assets/paneview/img/vsizegrip.png) center center no-repeat #adadad;
}

#pane-list .page-form {
  flex-direction: column;
}

.pane-view-form {
  flex: 1 1 auto;
  width: 70000%;
  height: 100vh;
  overflow: auto;
}
.pane-view-form .form-tabs-list {
  position: static;
}

#pane-form .page-head {
  top: 0px !important;
}

#page-pane_view > .page-head {
  z-index: 7;
}